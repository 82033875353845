import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38')
];

export const server_loads = [0,5];

export const dictionary = {
		"/": [~7],
		"/(app)/(protected)/accounts": [~22,[3]],
		"/(app)/(protected)/accounts/[id]": [~23,[3,5]],
		"/(app)/(protected)/accounts/[id]/activity": [~24,[3,5]],
		"/(app)/(protected)/accounts/[id]/connections": [~25,[3,5]],
		"/(app)/(protected)/accounts/[id]/connectors": [~26,[3,5]],
		"/(app)/(protected)/accounts/[id]/projects": [~27,[3,5]],
		"/(app)/(protected)/accounts/[id]/users": [~28,[3,5]],
		"/(app)/(protected)/activity": [~29,[3]],
		"/(app)/(auth)/auth/check_email": [8,[2]],
		"/(app)/(auth)/auth/reset_password": [~9,[2]],
		"/(app)/(auth)/auth/token_hash_error": [10,[2]],
		"/(app)/(auth)/auth/update_password": [~11,[2]],
		"/(app)/(protected)/connections": [~30,[3]],
		"/(app)/(protected)/connections/[id]": [31,[3]],
		"/(app)/(auth)/login": [~12,[2]],
		"/(app)/(auth)/login/sso": [~13,[2]],
		"/(app)/(protected)/oauth/authorization": [~32,[3]],
		"/(app)/(protected)/oauth/callback": [~33,[3]],
		"/(app)/(protected)/profile": [~34,[3]],
		"/(app)/(protected)/projects": [35,[3]],
		"/(app)/(protected)/projects/[id]": [~36,[3]],
		"/(app)/(protected)/projects/[id]/workflows": [~37,[3]],
		"/(app)/(protected)/projects/[id]/workflows/[workflowId]": [38,[3,6]],
		"/(app)/(auth)/register": [~14,[2]],
		"/(app)/(protected)/(wizard)/[[accounts]]/[[account_id]]/wizard/[wizard]/connect": [15,[3,4]],
		"/(app)/(protected)/(wizard)/[[accounts]]/[[account_id]]/wizard/[wizard]/connect/[id]": [~16,[3,4]],
		"/(app)/(protected)/(wizard)/[[accounts]]/[[account_id]]/wizard/[wizard]/done": [17,[3,4]],
		"/(app)/(protected)/(wizard)/[[accounts]]/[[account_id]]/wizard/[wizard]/processor": [18,[3,4]],
		"/(app)/(protected)/(wizard)/[[accounts]]/[[account_id]]/wizard/[wizard]/processor/[id]": [~19,[3,4]],
		"/(app)/(protected)/(wizard)/[[accounts]]/[[account_id]]/wizard/[wizard]/resources/[id]": [~20,[3,4]],
		"/(app)/(protected)/(wizard)/[[accounts]]/[[account_id]]/wizard/[wizard]/settings": [21,[3,4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';